<template>
  <div class="impact">
    <!-- <the-sub-navbar @select="selectScreen"></the-sub-navbar> -->
    <div  v-if="screen == 'proposed-story'" class="">
      <proposed-stories></proposed-stories>
    </div>
    <div v-else class="articles">
      <a-row :gutter="[18, 18]">
        <a-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="article in articles"
          :key="article.id"
        >
          <article-card
            :title="article.title"
            :author-name="article.authorName"
            :image="article.image"
            :profile-photo="article.profilePhoto"
            :text="article.description"
            :date="article.pub_date"
          ></article-card>
        </a-col>
      </a-row>
    </div>
    
    <!-- <div class="container"> -->
    <!-- <keep-alive>
      <component :is="activeScreen" no= 4></component>
    </keep-alive> -->
    <!-- </div> -->
  </div>
</template>
<script>
// import TheSubNavbar from "./TheSubnavbar.vue";
import ArticleCard from "../BaseComponents/ArticleCard.vue";
import ProposedStories from "./ProposedStories.vue";

export default {
  components: {
    // TheSubNavbar,
    ArticleCard,
    ProposedStories,
  },
  data() {
    return {
      screen: null,
      showLike: true,
      articles: [
        {
          id: 1,
          title: "Olympics 2021 - SOP's Being strictly followed",
          authorName: "Aliyan Awan",
          image: require("../../assets/images/card-image1@2x.png"),
          profilePhoto: "profile-pic.webp",
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled",
          pub_date: "23 Nov 2022",
        },
        {
          id: 2,
          title:
            "Elon Musk Chief Executive Officer of Tesla Motors",
          authorName: "Zaynab Farz",
          image: require("../../assets/images/Elon-Musk.jpg"),
          profilePhoto: "profile-pic.webp",
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled",
          pub_date: "23 Nov 2022",
        },
        {
          id: 3,
          title: "Tesla is organized into two main divisions based on their different product categories. Therefore there are two segments of infrastructure too. ",
          authorName: "Zaynab Farz",
          image: require("../../assets/images/car.jpg"),
          profilePhoto: "profile-pic.webp",
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled",
          pub_date: "23 Nov 2022",
        },
        {
          id: 4,
          title: "Tesla surpassed a market value of $1 trillion on Monday, making it the fifth such firm to reach the milestone.",
          authorName: "Zaynab Farz",
          image: require("../../assets/images/card-image2.png"),
          profilePhoto: "profile-pic.webp",
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled",
          pub_date: "23 Nov 2022",
        },
      ],
    };
  },
  mounted() {
    this.screen = "proposed-story";
  },
  methods: {
    selectScreen(screen) {
      this.screen = screen;
      // this.activeScreen = (screen == "impact-article" ? ArticleSlider : ProposedStories);
    },
  },
};
</script>

<style lang="scss" scoped>
.impact {
  margin: 0; 
  padding: 4.4rem 4rem;
  position: relative;
  z-index: 1;
  background-color: #f3f2f2;
  height: 100vh;
  .articles {
    position: relative;
    z-index: 20000;
    padding-right: 2rem;
  }
}
</style>
