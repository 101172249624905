<template>
  <div class="stories">
    <h3 v-if="getArticles == 0 && !searchingArticles" class="not-found">Your Highlighted Current Event Will Show Here</h3>
    <a-row :gutter="[18, 18]" v-if="!searchingArticles" >
      <a-col
        :xs="24"
        :sm="12"
        :md="12"
        :lg="8"
        v-for="(articles, index) in getArticles"
        :key="index"
      >
      <impact-acticle-card
        :id="articles.id"
        :title="articles.title"
        :description="articles.description"
        :publishedDate="articles.created_at"
        :authorName="articles.author.profile.full_name"
        :authorId="articles.author.id"
        :authorProfilePic="articles.author.profile.profile_picture"
        :media="articles.media"
        :userReaction="articles.user_reaction"
        :userReactionRepresentation="articles.users_reaction_representation"
        :emails="articles.emails"
        :collabrators="articles.invitees"
        :visibleBtnMenu="true"
        @visible-drawer="showDrawer"
        :currentPage="currentPage"
      ></impact-acticle-card>
      </a-col>
    </a-row>
    <a-row :gutter="[18, 18]" v-if="searchingArticles" >
      <a-col
        :xs="24"
        :sm="12"
        :md="12"
        :lg="8"
        v-for="index in 12"
        :key="index"
      >
        <a-skeleton
          class="impact-article-card-skeleton"
          active
          :title="{ width: 'full'  }"
          :paragraph="{ rows: 1, width: ['100%'] }"
        />
      </a-col>
    </a-row>
  </div>
  <story-drawer
    v-if="articleId"
    :show-drawer="showStoryDrawer"
    @close-drawer="closeDrawer"
    :storyId="articleId"
  ></story-drawer>

</template>
<script>
// import TheStory from "./TheStory.vue";
import storyDrawer from "./StoryDarwer.vue";
import ImpactActicleCard from "./ImpactActicleCard.vue";

export default {
  components: {
    // TheStory,
    storyDrawer,
    ImpactActicleCard
  },
  data(){
    return{
      searchingArticles: false,
      showStoryDrawer: false,
      articleId: null,
      currentPage: 0
    }
  },
  watch: {
    $route(to, _){
      this.showDrawer({ articleId: to.params.id });
    },
  },
  computed: {
    getArticles() {
      return this.$store.getters["impact/getImpactArticles"].results;
    },
    impactArticlesPagination() {
      return (
        this.$store.getters["impact/getImpactArticleNextUrl"] ||
        this.$store.getters["impact/getImpactArticlePreviousUrl"]
      );
    },
    totalImpactArticles() {
      return this.$store.getters["impact/getImpactArticles"].count;
    },
  },
  async mounted() {
    this.impactArticles();
    if (Object.keys(this.$route.params).length > 0) {
      this.showDrawer({ articleId: this.$route.params.id });
    }
    await this.emitter.on("searching-impact-articles", (searchStatus) => {
      this.searchingArticles = searchStatus;
    });
  },
  methods: {
    async impactArticles() {
      try {
        const payload = {
          query: "",
          offset: 0,
          limit: 9,
          author: ""
        };
        this.searchingArticles = true;
        const response = await this.$store.dispatch(
          "impact/fetchImpactArticle",
          payload
        );
        if (response.status == 200) {
          this.searchingArticles = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async changePage(page) {
      this.currentPage = page;
      const payload = {
        query: "",
        offset: (page - 1) * 9,
        limit: 9,
        author: ""
      };
      this.searchingArticles = true;
      await this.$store.dispatch("impact/fetchImpactArticle", payload);
      this.searchingArticles = false;
    },
    showDrawer(value){
      this.articleId = value.articleId;
      this.showStoryDrawer = true;
    },
    closeDrawer(){
      this.showStoryDrawer = false;
    }
  },
};
</script>

<style lang="scss">
.stories {
  position: relative;
  z-index: 20000;
  background-color: transparent;
  max-width: 140rem;
  margin: 0 auto;
  .impact-article-card-skeleton {
    .ant-skeleton-content {
      .ant-skeleton-title {
        height: 3rem;
        margin: 0.5rem 0 0;
      }
      .ant-skeleton-paragraph {
        margin-top: 1rem;
        li:first-child {
          height: 18rem;
        }
        li {
          height: 2rem;
        }
      }
    }
  }
  .not-found {
    font-size: 1.8rem;
    font-family: $font-primary-bold;
    text-align: center;
    color: gray;
    margin: 6rem;
  }
}
.stories::-webkit-scrollbar-track {
  background-color: #ffffff3f;
}

.stories::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  border-radius: 3rem;
}
.stories::-webkit-scrollbar-thumb {
  background-color: $color-primary;
  border-radius: 3rem;
}
</style>
